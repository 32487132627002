import {
  Prices,
  PriceAttribute,
} from '@printdeal/product-selector';
import Api from '../Api';

export interface ProductConfigurationPrices {
  productPageId: string;
  configuration: PriceAttribute[];
  prices: Prices;
}

type CheapestPriceAttributes = {
  deliveryType: string;
  printingProcess: string;
  quantity: number;
};

type DynamicRoute = {
  productPageId: string;
  configurationHash: string;
  configuration: PriceAttribute[];
  cheapestPriceAmount: number;
  cheapestPriceAttributes: CheapestPriceAttributes;
};

class DynamicProductRoutesService extends Api {
  async getCheapestDynamicProductRoute(productpageId: string): Promise<DynamicRoute> {
    const { data } = await this.httpClient.get<DynamicRoute>(`/product-routes/cheapest/${productpageId}`);
    return data;
  }

  async getMostPopularDynamicProductRoute(productpageId: string): Promise<DynamicRoute> {
    const { data } = await this.httpClient.get<DynamicRoute>(`/product-routes/popular/${productpageId}`);
    return data;
  }

  async saveProductConfigurationPrices(payload: ProductConfigurationPrices): Promise<void> {
    const { data } = await this.httpClient.post<void>('/price-tables', payload);
    return data;
  }

  async invalidateProductConfigurationPrices(productpageId: string): Promise<void> {
    const { data } = await this.httpClient.delete<void>(`/price-tables/${productpageId}`);
    return data;
  }
}

export default DynamicProductRoutesService;
